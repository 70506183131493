import { useEffect, useMemo } from 'react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { Box, Button, ButtonGroup, Flex, Grid, IconButton } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';

import { TypographyCore } from '~/components/core-components';

import { useBasketMemory } from '~/lib/cart/hooks';
import type { BasketElement } from '~/lib/cart/slice';
import { setDisabledElement, updateElementPrice } from '~/lib/cart/slice';
import { useAppDispatch, useAppSelector } from '~/lib/hooks';
import { useGetProductByIdQuery } from '~/lib/product/api';

import styles from './basket-product-element.module.css';

export const BasketProductElement = ({ index, product }: { index: number; product: BasketElement }) => {
    const dispatch = useAppDispatch();

    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });
    const { onDecrease, onIncrease } = useBasketMemory();
    const selectedCity = useAppSelector((state) => state.user.data.address?.id);
    // запрашиваем актуальную информацию для товаров в корзине
    const { data: actualProduct } = useGetProductByIdQuery({ id: product.id });

    const isDisabledElement = useMemo(
        () => actualProduct?.data.sale_points?.filter((item) => item.city_id === selectedCity).length,
        [selectedCity, actualProduct],
    );

    useEffect(() => {
        dispatch(setDisabledElement({ id: product.id, disabled: !isDisabledElement }));
    }, [isDisabledElement, product.id]);

    useEffect(() => {
        if (actualProduct?.data.price && actualProduct?.data.price !== product.price) {
            dispatch(updateElementPrice({ id: product.id, price: actualProduct?.data.price }));
        }
    }, [actualProduct, product]);

    return (
        <Grid
            width={isMobile ? '80vw' : 'inherit'}
            templateColumns={'1fr 3fr 1fr'}
            key={`${index} - ${product.name}`}
            gap='12px'
            pb='12px'
            pt='8px'
            className={styles['basket-item']}
        >
            <Box className={styles['icon-brand']} bgImage={product.src || '/nophoto.png'} />
            <Box maxWidth={isMobile ? '30vw' : 'inherit'}>
                <TypographyCore size={isMobile ? 'xs' : 's'} view='regular' className={`${styles.name} mb-8`}>
                    {product.name}
                </TypographyCore>
            </Box>
            {product.disabled ? (
                <TypographyCore size='s' view='regular' className='mb-8'>
                    Товар недоступен в этом городе
                </TypographyCore>
            ) : (
                <Box display={'flex'} flexDirection={'column'}>
                    <Flex justifyContent={'end'}>
                        <TypographyCore size='s' view='bold' className={`${styles.typography} mb-8`} useSeparate={true}>
                            {actualProduct?.data.price}
                        </TypographyCore>
                        <TypographyCore variant='span' size='s' view='bold'>
                            &nbsp;₽
                        </TypographyCore>
                    </Flex>
                    <ButtonGroup
                        isAttached
                        borderRadius='8px'
                        h='32px'
                        backgroundColor='rgba(244, 244, 244, 1)'
                        size='sm'
                        _hover={{
                            backgroundColor: 'rgba(205, 219, 211, 1)',
                        }}
                    >
                        <IconButton
                            backgroundColor='rgba(244, 244, 244, 1)'
                            icon={<MinusIcon />}
                            h='32px'
                            aria-label='minus product'
                            onClick={onDecrease(product.id)}
                            _hover={{
                                backgroundColor: 'rgba(205, 219, 211, 1)',
                            }}
                        />
                        <Button
                            backgroundColor='rgba(244, 244, 244, 1)'
                            _hover={{
                                backgroundColor: 'rgba(205, 219, 211, 1)',
                            }}
                            color='rgba(79, 79, 79, 1)'
                            variant='solid'
                            h='32px'
                            borderRadius='8px'
                        >
                            <TypographyCore size='m' view='medium'>
                                {product?.quantity}
                            </TypographyCore>
                        </Button>
                        <IconButton
                            onClick={onIncrease(product.id)}
                            h='32px'
                            backgroundColor='rgba(244, 244, 244, 1)'
                            icon={<AddIcon />}
                            aria-label='add product'
                            _hover={{
                                backgroundColor: 'rgba(205, 219, 211, 1)',
                            }}
                        />
                    </ButtonGroup>
                </Box>
            )}
        </Grid>
    );
};
