'use client';

import { Box, Icon, Link as ChakraLink } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';
import NextLink from 'next/link';
import Link from 'next/link';

import { useLazyGetDocumentsQuery } from '~/lib/documents/api';

import { SympeeHeart } from '../../../public/sympee-heart';
import { SympeeLogo } from '../../../public/sympee-logo';
import { TelegramLogo } from '../../../public/telegram-logo';
import { ButtonCore, TypographyCore } from '../core-components';

import '../../styles/variables.css';
import styles from './footer.module.css';

export const Footer = () => {
    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });
    const [triggerGetDocuments] = useLazyGetDocumentsQuery();

    const handleOpenDocumentPolicy = async () => {
        const results = await triggerGetDocuments();

        if (results.data) {
            window.open(results.data.data.policy);
        }
    };

    const handleOpenDocumentOferta = async () => {
        const results = await triggerGetDocuments();

        if (results.data) {
            window.open(results.data.data.oferta);
        }
    };

    return (
        <footer className={styles.box}>
            <div className={styles.container}>
                <div className={styles['footer-divider']}>
                    <div>
                        <div className={isMobile ? 'mb-16' : 'mb-8'}>
                            <Icon as={SympeeLogo} w='126px' h='40px' />
                        </div>
                        {isMobile ? (
                            <div className={`${styles['contact-container']} mb-20`}>
                                <TypographyCore size='m' view='medium'>
                                    <ChakraLink as={NextLink} href='/catalog/' _hover={{ textDecoration: 'none' }}>
                                        Каталог
                                    </ChakraLink>
                                </TypographyCore>

                                <TypographyCore size='m' view='medium'>
                                    <Link
                                        target='_blank'
                                        href='https://docs.google.com/forms/d/e/1FAIpQLSe5B3H6g_FZjxi5_BaXBY5_doOmcf2RXz9J0Wa4SPAPVrVZRA/viewform?usp=preview'
                                        passHref={true}
                                    >
                                        Стать партнёром
                                    </Link>
                                </TypographyCore>
                                <TypographyCore size='m' view='medium'>
                                    <Link target='_blank' href='https://telegram.me/sympee_help' passHref={true}>
                                        Поддержка
                                        <Icon as={SympeeHeart} w='20px' h='20px' />
                                    </Link>
                                </TypographyCore>
                            </div>
                        ) : null}
                        {isMobile ? (
                            <div className={`${styles['social-container']} mb-16`}>
                                <Link href='https://telegram.me/sympee_online' target='_blank' passHref={true}>
                                    <Icon as={TelegramLogo} height={'36px'} w={'36px'} color='rgba(255, 255, 255, 1)' />
                                </Link>
                            </div>
                        ) : (
                            <div className={styles['social-container']}>
                                <Link href='https://telegram.me/sympee_online' target='_blank' passHref={true}>
                                    <Icon as={TelegramLogo} height={'36px'} w={'36px'} color='rgba(247, 247, 247, 1)' />
                                </Link>
                            </div>
                        )}
                    </div>
                    {isMobile ? null : (
                        <Box textAlign={'end'}>
                            <div className={`${styles['contact-container']} mb-20`}>
                                <TypographyCore size='m' view='medium'>
                                    <ChakraLink as={NextLink} href='/catalog/' _hover={{ textDecoration: 'none' }}>
                                        Каталог
                                    </ChakraLink>
                                </TypographyCore>

                                <TypographyCore size='m' view='medium'>
                                    <Link
                                        target='_blank'
                                        href='https://docs.google.com/forms/d/e/1FAIpQLSe5B3H6g_FZjxi5_BaXBY5_doOmcf2RXz9J0Wa4SPAPVrVZRA/viewform?usp=preview'
                                        passHref={true}
                                    >
                                        Стать партнёром
                                    </Link>
                                </TypographyCore>
                            </div>
                            <TypographyCore size='m' view='medium'>
                                <Link target='_blank' href='https://telegram.me/sympee_help' passHref={true}>
                                    Поддержка
                                    <Icon as={SympeeHeart} w='20px' h='20px' />
                                </Link>
                            </TypographyCore>
                        </Box>
                    )}
                </div>
            </div>
            <div className={styles['container-info']}>
                {isMobile ? (
                    <div className={styles['container-rules']}>
                        <ButtonCore view='link' onClick={handleOpenDocumentPolicy} className={'mb-4'}>
                            <TypographyCore size={'s'} view='medium' color='heavy'>
                                Политика конфиденциальности
                            </TypographyCore>
                        </ButtonCore>
                        <ButtonCore view='link' onClick={handleOpenDocumentOferta} className={'mb-12'}>
                            <TypographyCore size={'s'} view='medium' color='heavy'>
                                Условия использования
                            </TypographyCore>
                        </ButtonCore>
                    </div>
                ) : null}
                <TypographyCore view={'regular'} color={'light'} className={styles['info-text']}>
                    ИП Широков Сергей Олегович, ОГРНИП 324784700198240, ИНН 781605925236, Электронная почта:  marketing@sympee-marketplace.ru, Адрес
                    регистрации: 192239, город Санкт-Петербург, улица Димитрова дом 12, корпус 2, квартира 70
                </TypographyCore>
                {isMobile ? null : (
                    <div className={styles['container-rules']}>
                        <ButtonCore view='link' onClick={handleOpenDocumentPolicy} className={'mb-4'}>
                            <TypographyCore size={'s'} view='medium' color='heavy'>
                                Политика конфиденциальности
                            </TypographyCore>
                        </ButtonCore>
                        <ButtonCore view='link' onClick={handleOpenDocumentOferta} className={'mb-4'}>
                            <TypographyCore size={'s'} view='medium' color='heavy'>
                                Условия использования
                            </TypographyCore>
                        </ButtonCore>
                    </div>
                )}
            </div>
        </footer>
    );
};
