import { createIcon } from '@chakra-ui/icons';

export const SympeeHeart = createIcon({
    displayName: 'SympeeHeart',
    viewBox: '0 0 20 20',
    path: (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M3.00042 7.70052C2.9727 5.82299 4.33383 4.22048 6.22772 3.83843C7.25781 3.63032 8.21861 3.8206 9.10396 4.35278C9.43193 4.5505 9.73218 4.79132 10.0401 5.01876C10.1063 5.06782 10.1418 5.07228 10.2033 5.01876C10.7084 4.58469 11.2581 4.21007 11.9017 3.99601C13.4537 3.48166 14.8318 3.81168 16.0205 4.87308C16.7149 5.49149 17.1029 6.28234 17.2138 7.18766C17.3016 7.89526 17.2046 8.59246 17.0198 9.27777C16.6733 10.5562 15.9943 11.6637 15.1228 12.6686C14.1974 13.7345 13.1058 14.6175 11.9217 15.4009C11.6045 15.6105 11.2842 15.8157 10.9547 16.0045C10.4174 16.3122 9.85382 16.3285 9.31953 16.0193C8.05386 15.2865 6.87595 14.4376 5.83509 13.4223C4.87121 12.4828 4.06592 11.4348 3.54241 10.2009C3.20212 9.39966 3.00811 8.56868 3.00042 7.70052ZM15.2691 7.71688C15.2475 7.55038 15.2398 7.38091 15.2014 7.21739C14.9242 6.01625 13.4907 5.30567 12.3559 5.89881C11.797 6.19166 11.332 6.58709 10.9347 7.05981C10.4851 7.59498 9.76297 7.59498 9.31491 7.06279C8.94229 6.61979 8.5127 6.24072 7.99535 5.9553C7.47029 5.66542 6.92061 5.59406 6.35244 5.80664C5.57333 6.09652 5.11141 6.64506 5.00824 7.45078C4.94819 7.91905 5.03596 8.37691 5.16222 8.82585C5.41935 9.75049 5.91207 10.5562 6.53105 11.2935C7.52111 12.4724 8.74212 13.3911 10.054 14.2072C10.1156 14.2459 10.1587 14.225 10.211 14.1938C10.9655 13.73 11.6861 13.2231 12.3574 12.6508C13.2628 11.8792 14.0589 11.0185 14.6193 9.97496C14.9981 9.26885 15.2352 8.52259 15.2691 7.71836V7.71688Z'
                fill='#FD6B00'
            />
        </svg>
    ),
});
